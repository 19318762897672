import React, { FC } from 'react'
import dynamic from 'next/dynamic'

const Reviews = dynamic(() => import('../components/Reviews'))

interface Props {
  title: string
}

const ReviewsContainer: FC<Props> = ({ title }) => {
  return (
    <>
      <Reviews title={title} />
    </>
  )
}

export default ReviewsContainer
